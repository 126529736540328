<script>
import gsap from 'gsap';
import { nextTick } from 'process';
export default {
  data(){
    return{
      posts: this.$store.state.opinions,
      timeline: null,
      isPaused: false,
      user_window: null,
    }
  },
  methods:{
    initAnimation(){
      let scroll = document.querySelector('.sliding_cont');
      
      let tl = gsap.timeline({repeat: 250});
      this.timeline = tl;
      tl.to(scroll, {scrollLeft: scroll.scrollWidth, duration: this.posts.length*10, ease: 'power1.out'});
      tl.to(scroll, {scrollLeft: 0, duration: this.posts.length*10, ease: 'power1.out'})

    },
    pauseAnimation(){
      if(this.user_window <= 768) return;
      this.timeline.pause()
    },
    resumeAnimation(){
      if(this.user_window <= 768) return;
      this.timeline.resume()
    },
    animPause_Start(){
      if(this.user_window <= 768) return;
      if(this.isPaused){
        this.resumeAnimation(); 
        this.isPaused = false;
        return;
      }
      this.pauseAnimation()
      this.isPaused = true;
    },
    setPostHeight(index) {
      const img = this.$el.querySelectorAll('img')[index];
      const height = img.clientHeight;
      this.postsHeight.splice(index, 1, height); // add or update the height in the array

    },
  },
  mounted(){
    this.user_window = window.innerWidth;

    if(window.innerWidth > 768){
      console.log("ISISI")
      this.initAnimation()
    }

  },
 
}
</script>
<template>
  <div class="opinions">
    <div class="section_title">
      <div class="texts">
        <p class="p-white roadradio700">ОТЗЫВЫ О</p>
        <p class="p-bronze caveat400">нашей работе...</p>
      </div>

    </div>

    <div class="slider_container">
      <div class="sliding_cont" @click="animPause_Start()">
        <ul>
          <li v-for="(post, k) in posts"><img :src="post"></li>
        </ul>
      </div>
      <img src="@/assets/images/cars/toyota_corolla.png" class="corolla" alt="">
    </div>
  </div>
</template>
<style src="../assets/css/pages/opinions.css"></style>
