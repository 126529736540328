<script>
import { gsap } from 'gsap';
export default {
  data(){
    return{
      posts: this.$store.state.about_out_work,
      post_able: {
        first: 0,
      },
      previousRandomIndices: [0, 1]
    }
  },
  methods:{
    nextContent(){
      this.post_able.first = this.post_able.first +1;
      if(this.post_able.first == this.posts.length){
        this.post_able.first = 0;
      }
    },  
    goToPreviousRandom() {
      this.post_able.first = this.post_able.first -1;
      if(this.post_able.first == -1){
        this.post_able.first = this.posts.length-1;
      }
    },
    scaleElem(event, stan){
      if(this.isMobile){
        if(stan){
          gsap.to(event.target, {margin: '0 5px', duration: 0.2})
        }else{
          gsap.to(event.target, {margin: '0', duration: 0.2})
        }
      }else{
        if(stan){
          gsap.to(event.target, {margin: '0 20px', duration: 0.2})
        }else{
          gsap.to(event.target, {margin: '0', duration: 0.2})
        }
      }
    }
  },
  created(){
    if(window.innerWidth <= 768){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
  }
}
</script>
<template>
  <div class="about_our_work">
    <div class="section_title">
      <p class="p-white roadradio700">НЕМНОГО О</p>
      <p class="p-bronze caveat400">нашей работе...</p>
    </div>

    <div class="scroll_section">
      <ul>
        <li v-for="(post, k) in posts" v-show="k == post_able.first">
          <div class="left_section">
            <div class="big_photos">
              <img v-for="big in post.images.big" :src="big">
            </div>
            <div class="small_photos">
              <div class="img_container"  v-for="small in post.images.small" @mouseover="scaleElem($event, true)" @mouseleave="scaleElem($event, false)">
                <img :src="small">
              </div>
            </div>

          </div>

          <div class="right_section">
            <p class="p-white roadradio700">{{post.texts.header.white}} <br><span class="p-bronze">{{post.texts.header.bronze}}</span></p>

            <div class="line_content_cutting"></div>
            
            <div class="car_description">
              <p class="p-white montserrat500" name="desc">{{post.texts.description}}</p>
              <p class="p-white50 montserrat500" name="desc">{{post.texts.ending}}</p>

              <div class="bottom_description">
                <div class="car_details">
                  <p class="p-white montserrat700">АВТОМОБИЛЬ: {{post.vehicle.name}}</p>
                  <p class="p-white montserrat500">Год выпуска: {{post.vehicle.production_year}};</p>
                  <p class="p-white montserrat500">Объём ДВС: {{post.vehicle.engine}};</p>
                  <p class="p-white montserrat500">Пробег: {{post.vehicle.mileage.toLocaleString()}} км.</p>

                  <div class="buttons_slider">
                    <div class="button_left" @click="goToPreviousRandom()">
                      <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 1.87727L10.1799 0L0 10.5L10.1799 21L12 19.1227L3.6401 10.5L12 1.87727Z" fill="#505050"/>
                      </svg>
                    </div>
                    <p class="p-white montserrat500">{{post_able.first + 1}} / {{ posts.length }}</p>
                    <div class="button_right" @click="nextContent()">
                      <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.764526 2.23189L2.58458 0.354614L12.7645 10.8546L2.58458 21.3546L0.764526 19.4773L9.12442 10.8546L0.764526 2.23189Z" fill="white"/>
                      </svg>
                    </div>
                  </div>
                </div>

                <a class="button_call calc" v-if="post.link" :href="post.link" target="_blank">
                  <div class="container_text" style="width: calc(var(--piv)*333);">
                    <p class="p-white montserrat600">смотреть подробнее...</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<style src="../assets/css/pages/about_our_work.css"></style>
