<script>
import gsap from 'gsap';
import axios from 'axios'
export default {
  data(){
    return{
      type_mobile: true, 

      form_filling:{
        step: 0,
        with_mileage: false,
        time_to_buy: 'week',
        phone: null,
        car_year: null,
        money: null,
      },
      errText: null,
    }
  },
  methods:{
    form_click(type, value){
      console.log(type, value)
      this.form_filling[type] = value;
    },
    acceptStep(){

      this.errText = null;
      if(this.form_filling.step == 2 && !this.form_filling.phone){
        this.errText = 'Не правильно заполненно поле ввода.'
        return;
      }
      if(this.form_filling.step == 3 && !this.form_filling.money){
        this.errText = 'Не правильно заполненно поле ввода.'
        return;
      }
      if(this.form_filling.step == 4 && !this.form_filling.car_year){
        this.errText = 'Не правильно заполненно поле ввода.'
        return;
      }
      if(this.form_filling.step == 4){
        if(this.form_filling.time_to_buy == 'not_selected'){
          this.form_filling.time_to_buy = 'Не ограничен'
        }else if(this.form_filling.time_to_buy == 'week'){
          this.form_filling.time_to_buy = 'Неделя'
        }else if(this.form_filling.time_to_buy == '2-3_weeks'){
          this.form_filling.time_to_buy = '2-3 недели'
        }else if(this.form_filling.time_to_buy == '2_months'){
          this.form_filling.time_to_buy = '2 месяца'
        }
        if(this.form_filling.with_mileage){
          this.form_filling.with_mileage = 'Без пробега'
        }else{
          this.form_filling.with_mileage = 'С пробегом'
        }
        const dataMail = new FormData();
        dataMail.append('number', this.form_filling.phone);
        dataMail.append('when', this.form_filling.time_to_buy);
        dataMail.append('budget', this.form_filling.money);
        dataMail.append('car_type', this.form_filling.with_mileage);
        dataMail.append('model', this.form_filling.car_year);

        if(!this.form_filling.phone || !this.form_filling.time_to_buy || !this.form_filling.money || !this.form_filling.car_year) return;

        
        axios.post('assets/php/mail.php', dataMail)
        .then(response => {
          console.log(response.data);
  console.log('sender data is done') 
     })
        .catch(error => {
          console.error(error);
        });
      }
      this.form_filling.step = this.form_filling.step+1;
    }
  },
  mounted(){
  },
  created(){
    if(window.innerWidth <= 768){
      this.type_mobile = true;
    }else{
      this.type_mobile = false;
    }
  }
}
</script>
<template>
  <div class="mailing" id="scroll_price">
    <div class="head">
      <div class="section_title">
        <div class="texts">
          <p class="p-white roadradio700">ПОДБЕРЕМ НАДЕЖНЫЙ</p>
          <p class="p-bronze caveat400">Автомобиль</p>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="left_cont">
        <div class="form">
          <div class="head">
            <p class="montserrat600 p-white">Осталось вопросов <span class="p-bronze">{{form_filling.step+1}} из 6</span></p>
            <p class="montserrat500 p-white">Ответьте на <span class="p-bronze">6</span> вопросов.</p>
          </div>

          <div class="question" v-show="form_filling.step == 0">
            <div class="data">
              <div class="step_nr"><p class="p-white montserrat600">1</p></div>
              <div class="texts">
                <p class="p-bronze montserrat600">КАКОЙ АВТОМОБИЛЬ ВЫ ХОТИТЕ КУПИТЬ?</p>
                <p class="p-white montserrat500">Какое-то описание</p>
              </div>
            </div>
            <div class="actions">
              <div class="option_radio" :class="form_filling.with_mileage ? '' : 'active'" @click="form_click('with_mileage', false)">
                <p class="p-white montserrat400">Новый автомобиль</p>
              </div>
              <div class="option_radio" :class="form_filling.with_mileage ? 'active' : ''" @click="form_click('with_mileage', true)">
                <p class="p-white montserrat400">С пробегом</p>
              </div>
            </div>
            <div class="button_call">
              <div class="container_text" @click="acceptStep()">
                <p class="p-white montserrat600">следующий вопрос</p>
              </div>
            </div>
          </div>

          <div class="question" v-show="form_filling.step == 1">
            <div class="data">
              <div class="step_nr"><p class="p-white montserrat600">2</p></div>
              <div class="texts">
                <p class="p-bronze montserrat600">КОГДА ПЛАНИРУЕТЕ ПОКУПКУ?</p>
                <p class="p-white montserrat500">Какое-то описание</p>
              </div>
            </div>
            <div class="actions">
              <div class="option_radio" @click="form_click('time_to_buy', 'week')" :class="form_filling.time_to_buy == 'week' ? 'active' : ''">
                <p class="p-white montserrat400">Ближайшую неделю</p>
              </div>
              <div class="option_radio" @click="form_click('time_to_buy', '2-3_weeks')" :class="form_filling.time_to_buy == '2-3_weeks' ? 'active' : ''">
                <p class="p-white montserrat400">Ближайшие 2-3 недели</p>
              </div>
              <div class="option_radio" @click="form_click('time_to_buy', '2_months')" :class="form_filling.time_to_buy == '2_months' ? 'active' : ''">
                <p class="p-white montserrat400">Ближайшие 2 месяца</p>
              </div>
              <div class="option_radio" @click="form_click('time_to_buy', 'not_selected')" :class="form_filling.time_to_buy == 'not_selected' ? 'active' : ''">
                <p class="p-white montserrat400">Во времени не ограничен</p>
              </div>
            </div>
            <div class="button_call">
              <div class="container_text" @click="acceptStep()">
                <p class="p-white montserrat600">следующий вопрос</p>
              </div>
            </div>
          </div>

          <div class="question" v-show="form_filling.step == 2">
            <div class="data">
              <div class="step_nr"><p class="p-white montserrat600">3</p></div>
              <div class="texts">
                <p class="p-bronze montserrat600">ОСТАВЬТЕ ВАШ НОМЕР ТЕЛЕФОНА</p>
                <p class="p-white montserrat500">Вам перезвонит менеджер и проведет консультацию</p>
              </div>
            </div>
            <div class="actions">
              <div class="option_radio" :class="form_filling.phone ? 'active' : ''">
                <div class="inp">
                  <input v-model="form_filling.phone" type="text" class="p-white montserrat400" name="" placeholder="Введите ваш номер телефона" id="">
                </div>
              </div>
            </div>
                        <p class="p-bronze montserrat600" name=err>{{ errText }}</p>
            <div class="button_call">
              <div class="container_text" @click="acceptStep()">
                <p class="p-white montserrat600">следующий вопрос</p>
              </div>
            </div>
          </div>

          <div class="question" v-show="form_filling.step == 3">
            <div class="data">
              <div class="step_nr"><p class="p-white montserrat600">4</p></div>
              <div class="texts">
                <p class="p-bronze montserrat600">КАКОЙ У ВАС БЮДЖЕТ В РУБЛЯХ?</p>
                <p class="p-white montserrat500">Какое-то описание</p>
              </div>
            </div>
            <div class="actions">
              <div class="option_radio" :class="form_filling.money ? 'active' : ''">
                <div class="inp">
                  <input v-model="form_filling.money" type="text" class="p-white montserrat400" name="" placeholder="Введите сумму" id="">
                </div>
              </div>
            </div>
            <p class="p-bronze montserrat600" name=err>{{ errText }}</p>
            <div class="button_call">
              <div class="container_text" @click="acceptStep()">
                <p class="p-white montserrat600">следующий вопрос</p>
              </div>
            </div>
          </div>

          <div class="question" v-show="form_filling.step == 4">
            <div class="data">
              <div class="step_nr"><p class="p-white montserrat600">5</p></div>
              <div class="texts">
                <p class="p-bronze montserrat600">КАКУЮ МОДЕЛЬ И МАРКУ ВЫ ПРЕДПОЧИТАЕТЕ?</p>
                <p class="p-white montserrat500">Какое-то описание</p>
              </div>
            </div>
            <div class="actions">
              <div class="option_radio" :class="form_filling.car_year ? 'active' : ''">
                <div class="inp">
                  <input v-model="form_filling.car_year" type="text" class="p-white montserrat400" name="" placeholder="Введите модель и марку" id="">
                </div>
              </div>
            </div>
                        <p class="p-bronze montserrat600" name=err>{{ errText }}</p>
            <div class="button_call">
              <div class="container_text" @click="acceptStep()">
                <p class="p-white montserrat600">следующий вопрос</p>
              </div>
            </div>
          </div>

          <div class="question" v-show="form_filling.step == 5">
            <div class="data">
              <div class="step_nr"><p class="p-white montserrat600">6</p></div>
              <div class="texts">
                <p class="p-bronze montserrat600">МЕНЕДЖЕР ПЕРЕЗВОНИТ ВАМ ЧЕРЕЗ 10 МИНУТ</p>
                <p class="p-white montserrat500">и проконсультирует Вас по интересующим вопросами</p>
              </div>
            </div>
            <div class="button_call">
              <a class="container_text" href="https://vk.com/avtolabs" style="background: linear-gradient(90deg, rgba(106, 157, 255, 0.91) 0%, rgba(109, 150, 255, 0.91) 100%);">
                <p class="p-white montserrat600" style="color: white;">ГРУППА ВКОНТАКТЕ</p>
              </a>
            </div>
            <div class="button_call last_quest">
              <a class="container_text" href="https://vk.com/avtolabs">
                <p class="p-white montserrat600">МЕНЕДЖЕР НЕ ПЕРЕЗВОНИЛ</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <img class="right_img" src="../assets/images/logo.png">
    </div>

    
  </div>
  <div class="image_cars_mail">
    <img class="ty_corolla" src="../assets/images/cars/toyota_corolla.png">
    <img class="vw_polo" src="../assets/images/cars/vw_polo.png">
  </div>
</template>
<style src="../assets/css/pages/mailing.css"></style>
