<template>
  <div class="wrapped">
    <div class="images_bg">
      <img src="../assets/images/backgrounds/items/all_left.png" class="color_dodge global_style_image fire_left">
      <img src="../assets/images/backgrounds/items/all_right.png" class="color_dodge global_style_image fire_right">
      <img src="../assets/images/backgrounds/bg_1.png" class="global_style_image intro_img">
      <!-- <img src="../assets/images/backgrounds/items/enter.png" class="color_dodge global_style_image intro_fire"> -->
      <!-- <img src="../assets/images/backgrounds/items/enter_right.png" class="color_dodge global_style_image intro_fire_2"> -->
      <img src="../assets/images/backgrounds/items/opinions_wheel.png" class="global_style_image opinions_wheel">
      <!-- <img src="../assets/images/backgrounds/items/opinions_fire.png" class="color_dodge global_style_image opinions_fire"> -->
      <img src="../assets/images/backgrounds/items/opinions_bg.png" class="global_style_image opinions_bg">
    </div>
    <HeaderComp v-show="user_window > 768"></HeaderComp>
    <headerMobile v-show="user_window < 768"></headerMobile>
    <intro_page class="gsap_anim" id="scroll_main_page"></intro_page>
    <about_our_work class="gsap_anim" id="scroll_about_our_work"></about_our_work>
    <opinions class="gsap_anim" id="scroll_opinions"></opinions>
    <how_we_works class="gsap_anim" id="scroll_how_we_works"></how_we_works>
    <mailing class="gsap_anim"></mailing>
    <footerVue id="scroll_contact"></footerVue>
  </div>
</template>

<script>
// pages
import HeaderComp from '@/components/header.vue'
import intro_page from '@/components/intro_page.vue';
import about_our_work from '@/components/about_our_work.vue';
import opinions from '@/components/opinions.vue';
import how_we_works from '@/components/how_we_works.vue';
import mailing from '@/components/mailing.vue';
import footerVue from '@/components/footer.vue'
import headerMobile from '@/components/header_mobile.vue';

// animations
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { all } from 'axios';
gsap.registerPlugin(ScrollTrigger);


export default {
  name: 'HomeView',
  data(){
    return{
      user_window: null,
    }
  },
  mounted() {
    const all_elems = document.querySelectorAll('.gsap_anim');
    for (let k = 0; k < all_elems.length; k++) {
      const myElement = all_elems[k];

      gsap.set(myElement, { opacity: 0 });

      ScrollTrigger.create({
        trigger: myElement,
        start: 'top 80%',
        onEnter: () => gsap.fromTo(myElement, {x: 400, opacity: 0, duration: 1 }, { x: 0,opacity: 1, duration: 1 })
      });
    }
  },
  components: {
    HeaderComp,
    headerMobile,
    intro_page,
    about_our_work,
    opinions,
    how_we_works,
    mailing,
    footerVue,
  },
  created(){
    this.user_window = window.innerWidth
  }
}
</script>
<style src="../assets/css/pages/header.css"></style>