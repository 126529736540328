<script>
import gsap from 'gsap';
export default {
  data(){
    return{
      type_mobile: true, 
      posts: this.$store.state.opinions,
      timeline: null,
    }
  },
  methods:{
    initAnimation(){
      let scroll = document.querySelector('.sliding_cont');
      
      let tl = gsap.timeline({repeat: 250});
      this.timeline = tl;
      tl.to(scroll, {scrollLeft: scroll.scrollWidth, duration: this.posts.length*10, ease: 'power1.out'});
      tl.to(scroll, {scrollLeft: 0, duration: this.posts.length*10, ease: 'power1.out'})

    },
    pauseAnimation(){
      this.timeline.pause()
    },
    resumeAnimation(){
      this.timeline.resume()
    },
  },
  mounted(){

    if(!this.type_mobile){
      this.initAnimation()
    }
  },
  created(){
    if(window.innerWidth <= 768){
      this.type_mobile = true;
    }else{
      this.type_mobile = false;
    }
  }
}
</script>
<template>
  <div class="how_we_works">
    <div class="head">
      <div class="section_title">
        <div class="texts">
          <p class="p-white roadradio700">КАК МЫ</p>
          <p class="p-bronze caveat400">работаем?</p>
        </div>
        <div class="logo">
          <img src="../assets/images/logo.png" alt="">
        </div>
      </div>
    </div>

    <div class="steps_conain">
      <div class="grid_for_steps">
        <div class="step step1" style="grid-area: step1;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">1</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">КОНСУЛЬТАЦИЯ</p>
              <p class="p-white montserrat500">Выясняем ваше желание и предлагаем свои</p>
            </div>
  
          </div>
        </div>
  
        <div class="step step2" style="grid-area: step2;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">2</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">ПОДПИСАНИЕ ДОГОВОРА</p>
            </div>
          </div>
          <div class="img_container">
            <img src="https://cdn.discordapp.com/attachments/785161343681560608/1088187978028961964/image.png" alt="">
          </div>
          <a class="button" href="https://docs.google.com/document/d/1WG1Kbzk-0MQTgSBAme8lNL4jYi_i8kEd5sxQVD2D7r4/edit?usp=sharing">
            <p class="p-dark montserrat600">Перейти к договору</p>
          </a>
        </div>
  
        <div class="step step3" style="grid-area: step3;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">3</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">СТАРТ ПОДБОРА</p>
              <p class="p-white montserrat500">Скидываем списки автомобилей на одобрение</p>
            </div>
          </div>
          <div class="img_container">
            <img src="https://cdn.discordapp.com/attachments/785161343681560608/1088195214134476820/image.png" alt="">
          </div>
        </div>
  
        <div class="step step4" style="grid-area: step4;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">4</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">ПЕРВИЧНЫЙ ОСМОТР</p>
              <p class="p-white montserrat500">— КУЗОВА, САЛОНА, ГЕОМАТРИИ, БЕЗОПАНОСТИ И КОМПЬЮТЕРНАЯ ДИАГОСТИКА</p>
            </div>
          </div>
          <div class="img_container">
            <iframe async="" src="https://vk.com/video_ext.php?oid=-214514268&amp;id=456239036&amp;hash=c726f1d29b70c657&amp;hd=1" width="1179" height="430" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen=""></iframe>
          </div>
        </div>
  
        <div class="step step5" style="grid-area: step5;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">5</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">ОСНОВНАЯ ДИАГНОСТИКА</p>
              <p class="p-white montserrat500">После того как прошёл первичный осмотр, вам высылают отчет, если автомобиль мы рекомендуем к основной диагностике, то перед вами выбор: <br v-if="!type_mobile"> Первый - продолжить осмотр на СТО, сделать эндоскопию двигателя, продиагностировать ходовую, сформировать полный отчет; <br v-if="!type_mobile"> Второй - отказаться на авто на первичном осмотре, мы выезжаем на следующий вариант.</p>
            </div>
          </div>
          <div class="img_container">
            <img src="https://cdn.discordapp.com/attachments/785161343681560608/1088198026411130981/image.png" alt="">
          </div>
        </div>
  
        <div class="step step6" style="grid-area: step6;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">6</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">ПОДГОТОВКА ДОКУМЕНТОВ</p>
              <p class="p-white montserrat500">— ДКП, ТЕХ.ОСМОТР, СТРАХОВКА</p>
            </div>
          </div>
          <div class="img_container">
            <img src="https://cdn.discordapp.com/attachments/785161343681560608/1088198507044814868/image.png" alt="">
          </div>
        </div>
  
        <div class="step step7" style="grid-area: step7;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">7</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">ЛИЧНАЯ ВСТРЕЧА</p>
              <p class="p-white montserrat500">Мы встречаем Вас в назначенную дату возле автовокзала, а затем сопровождаем юридическую часть сделки (ДКП).</p>
            </div>
          </div>
          <div class="img_container">
            <img src="https://cdn.discordapp.com/attachments/785161343681560608/1088199055961759785/image.png" alt="">
          </div>
        </div>
  
        <div class="step step8" style="grid-area: step8;">
          <div class="data">
            <div class="step_nr"><p class="p-white montserrat600">8</p></div>
            <div class="texts">
              <p class="p-bronze montserrat600">ОФОРМЛЕНИЕ АВТО В МРЭО</p>
              <p class="p-white montserrat500">В этот же день мы едем в МРЭО и ставим автомобиль на учёт (занимает 2-3 часа). После оформления вывозим вас за город в сторону ЛНР или ДНР.</p>
            </div>
          </div>
          <div class="img_container">
            <img src="https://media.discordapp.net/attachments/785161343681560608/1088199182923337738/image.png" alt="">
          </div>
        </div>
  
      </div>
      <div class="arrow_cont">
        <svg width="24" height="45" viewBox="0 0 24 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9863 39.375C17.9863 40.8668 17.3527 42.2976 16.2248 43.3525C15.0969 44.4074 13.5672 45 11.9721 45C10.3771 45 8.84732 44.4074 7.71944 43.3525C6.59156 42.2976 5.95793 40.8668 5.95793 39.375C5.95793 37.8832 6.59156 36.4524 7.71944 35.3975C8.84732 34.3426 10.3771 33.75 11.9721 33.75C13.5672 33.75 15.0969 34.3426 16.2248 35.3975C17.3527 36.4524 17.9863 37.8832 17.9863 39.375ZM23.3389 14.5097C22.916 14.1147 22.3428 13.8928 21.7452 13.8928C21.1475 13.8928 20.5743 14.1147 20.1514 14.5097L14.2274 20.0503V2.10938C14.2274 1.54993 13.9898 1.01341 13.5669 0.617822C13.1439 0.222237 12.5703 0 11.9721 0C11.374 0 10.8003 0.222237 10.3774 0.617822C9.95441 1.01341 9.71679 1.54993 9.71679 2.10938V20.0503L3.79282 14.5097C3.36529 14.1371 2.79981 13.9342 2.21553 13.9439C1.63125 13.9535 1.07378 14.1749 0.660568 14.5614C0.247354 14.9479 0.0106603 15.4692 0.000351371 16.0157C-0.00995759 16.5622 0.206923 17.0911 0.605302 17.4909L10.3784 26.6316L11.9721 28.125L13.5659 26.6344L23.3389 17.4937C23.5485 17.2979 23.7148 17.0653 23.8282 16.8093C23.9416 16.5532 24 16.2788 24 16.0017C24 15.7246 23.9416 15.4502 23.8282 15.1942C23.7148 14.9382 23.5485 14.7056 23.3389 14.5097Z" fill="white"/>
        </svg>
      </div>
    </div>


  </div>
</template>
<style src="../assets/css/pages/how_we_works.css"></style>
