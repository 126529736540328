import { createStore } from 'vuex'

export default createStore({
  state: {
    intro_page_posts: [
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_603',
        description: 'Полное сопровождение сделки и подбора авто для клиента из ДНР',
        img: 'https://cdn.discordapp.com/attachments/785161343681560608/1090977027881979904/image.png'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_733',
        description: 'Оформили Крету для клиента из Луганска',
        img: 'https://sun9-78.userapi.com/impg/HHZdTLC2N402wF9wS6eoOf-wBGNujsxJSswt2g/SNwN2E9yI7U.jpg?size=810x1080&quality=95&sign=c7fc9963e6ba685853a45d88dcd19355&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_867',
        description: 'Нашли крутейшего дизельного Дастера с уникальной комплектацией!',
        img: 'https://sun9-2.userapi.com/impg/1vm418FY-PvJnkDNFYaK0rsGLPWy0IDrnyeaVg/TavMzpMAFRg.jpg?size=768x1024&quality=95&sign=bf0c348247119c7cab516e2271c51776&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_880',
        description: 'Сделали подбор Hyundai Solaris для клиентов из Донецка!',
        img: 'https://sun9-71.userapi.com/impg/WUMVO-hYp6nzjxJk9etdVd0T4qkk59X3Tg-owA/BAo9eON2d04.jpg?size=768x1024&quality=95&sign=bb508ce2c25dce77b69ba506a2db1f37&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_897',
        description: 'Подбор сроком в 1 день',
        img: 'https://sun9-75.userapi.com/impg/pGQg0-7mEUv_nU1ojRleM4mD0-m_uqNIGyr35g/uOWxoLhA7qA.jpg?size=810x1080&quality=95&sign=fde0ead3c44c0eab748dd15e2c8ff8a3&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_929',
        description: 'Kia Rio за которую шла борьба',
        img: 'https://sun9-47.userapi.com/impg/oOi_Trgbl7a1RJM-rT46YT1qHcXKhCGU07rgQg/oOSSm9R1gK8.jpg?size=810x1080&quality=95&sign=b2aa84146416241a5c64fc2acf10d5d6&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_966',
        description: 'Подобрали Hyundai Creta ниже рынка на 200,000 рублей!',
        img: 'https://sun9-5.userapi.com/impg/EMNlHo8p9Zm9cfiyAp8aEH0EsAx9g87asYaeGA/yLGeNmmkT4w.jpg?size=810x1080&quality=95&sign=6eb6fc791b0d68a50513ade8dd914e3d&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_1209',
        description: 'Старые года и большие цифры пробега — это плохо?',
        img: 'https://sun9-77.userapi.com/impg/JScygHgf12LVz0MR0f0A6pMDXRr4x9RhWleSpw/um7aGHIeWOU.jpg?size=810x1080&quality=95&sign=0c8f24228d105f14aa68cf1838b3e718&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_370',
        description: 'Универсал для большой семьи за 850.000 рублей?',
        img: 'https://sun9-65.userapi.com/impg/5aRz00rF6NNAb-u53dobeHcxeLZlrBo7LupjTg/r_U63AHhsuo.jpg?size=810x1080&quality=95&sign=59fc98f46b66ca4c89686bd475b1a784&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_431',
        description: 'Для врачей, военных и социальных профессий мы подбираем автомобили без очереди!',
        img: 'https://sun9-39.userapi.com/impg/ilj3cll_-cfw0UW9ah2TrwPGeYTOOwErUGY_zA/FUIKFpjb_lE.jpg?size=810x1080&quality=95&sign=34ca2a0fcdb1cb75d13a8388f1d7b919&type=album'
      },
      {
        link: 'https://vk.com/avtolabs?w=wall-214514268_703',
        description: 'Легко ли найти автомобиль за 2.5 млн рублей?',
        img: 'https://sun9-78.userapi.com/impg/_BZ1dFBeHCe807LS4voVr6s5zryUUFCD4mzuSg/En7Tstzyb4U.jpg?size=810x1080&quality=95&sign=eb9ddd5be4fa80b07b9a7d4b8b01199b&type=album'
      },
    ],

    about_out_work: [
      {
        images: {
          big: ['https://sun9-78.userapi.com/impg/_BZ1dFBeHCe807LS4voVr6s5zryUUFCD4mzuSg/En7Tstzyb4U.jpg?size=453x604&quality=95&sign=d36ce9eb669f42a290c9f446c9715a9c&c_uniq_tag=_wMipXRHNzzbbQt_AeTDROPAxNbTkfVNEGq4MIYwHSE&type=album', 'https://sun9-6.userapi.com/impg/flj9dd72tKsbP3o5xgbDT6TTbcrMAThEBf8TnQ/fllalHl-A_U.jpg?size=453x604&quality=95&sign=9ea1e96dee7323c6d07a317fb014635d&c_uniq_tag=vJOd-_Xb6y2vufEaGmn5gvSXlLaHx2jU0O7w9U5FocY&type=album'],
          small: ['https://sun9-56.userapi.com/impg/86lnes4yhuhn3AIelC9-DttiwbdV8mVduY_cig/36SQBPareQk.jpg?size=453x604&quality=95&sign=9202e2b171f796904bc627db10932598&c_uniq_tag=38c5snApy2rVFWURgkp1AB0zeONzJ3Nizll6zPHZFgI&type=album', 'https://sun9-22.userapi.com/impg/iMHb8zueq-wxc4jALwVUeoWuqacEmuOlL9QgeA/Qp-h8RS08W4.jpg?size=453x604&quality=95&sign=89ca881b667093c755cf65f90b77dbd8&c_uniq_tag=NXgV1UQ9IYmLuHL9OIl1jXZ8-KH6kYTwT-Dfw3feqPQ&type=album', 'https://sun9-17.userapi.com/impg/U0K8glocv4haf4J-MRt20mGhxZZnnLtI3fzGCw/VJV-ujOFdws.jpg?size=604x453&quality=95&sign=45a00f4d9c2845e2c364acbb5e82a241&c_uniq_tag=bXJz8TK18V8LtGkp1v_A4CDdxlCcMBLxAMcjU2jPLEI&type=album', 'https://sun9-26.userapi.com/impg/PrhNo9cuEwjtY-gBHa0_ttpQIvYexMHgPlobNQ/SW4XLlbfXYE.jpg?size=453x604&quality=95&sign=6080be45229ae8cbca39563dec5fdbb7&c_uniq_tag=2RlmxI9RTiVs7mk4Xt3uQkCIm8dz_9D1fuzWU7LHq9Q&type=album']
        },
        texts: {
          header: {
            white: 'Легко ли найти автомобиль',
            bronze: 'за 2.5 млн рублей?',
          },
          description: 'К нам обратился Валерий, с просьбой подобрать под ключ живую Тигу. Бюджет позволял это сделать, принялись искать варианты и просто были в шоке: из восьми вариантов хорошим оказался лишь один. Крученые болты на капоте, подкрашивание под толщиномеров, множество ошибок по компьютерной диагностикой — это лишь то, что находили по первичной проверке у автомобиля за два с половинной миллиона.',
          ending: 'На автомобиль выдана гарантия от AvtoLabs и полностью рекомендуем к покупке. Помогли Валерию оформить авто в МРЭО, вывезли автомобиль за город и отправили довольными на Луганск!',
        },
        vehicle: {
          name: 'Volkswagen Tiguan',
          production_year: '2020',
          engine: '2.0 TSI 4MOTION DSG (180 л.с.)',
          mileage: 71506,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_703',
      },
      {
        images: {
          big: ['https://sun9-2.userapi.com/impg/Au72xKkzM2H4kPfm_7Rk1UG-UIKxKdC-nAFe3A/So1QyuteVRA.jpg?size=453x604&quality=95&sign=c5752fbb87ad34cea404bc4c3465a24e&c_uniq_tag=wElpWWnBX_y2AO5ILIXi7BCWQY46Rzw6RQcy4WKmfh4&type=album', 'https://sun9-77.userapi.com/impg/JScygHgf12LVz0MR0f0A6pMDXRr4x9RhWleSpw/um7aGHIeWOU.jpg?size=453x604&quality=95&sign=25f5d675a785b5032261c318b9b7d208&c_uniq_tag=SEnfpUZ3SfzLksZhKtUDM2_D1-dmpq7vscY5KPwaoy0&type=album'],
          small: ['https://sun9-74.userapi.com/impg/9tBqO6XnMugaqEUNVr2Ae2SLw27Eb2KzFs3nwg/vh8THDq9QFY.jpg?size=97x130&quality=95&sign=aeb022a745662d7af1639c7d082f3410&c_uniq_tag=miMVSeaCibXz3EAgCuSg4qwHEPIYtm3mF4EACnXQRKs&type=album', 'https://sun9-80.userapi.com/impg/y3gb1feORMxlNvPB3o6f4zuCUqTdOkgDNVS-QQ/DjYwxC1xvSY.jpg?size=130x97&quality=95&sign=a1e797eb4315ea6cd6dc31eab764971e&c_uniq_tag=7Mhjsmz56DEqSsv9s69AAq3TI3VnX956ZCvoDIrNDZo&type=album', 'https://sun9-52.userapi.com/impg/ZW7N9TFa6vBcEvtBZfk_1zYeUmC7YOqYxO5dZA/rWTJ42eiIBo.jpg?size=130x97&quality=95&sign=0cdf93f152362d08a833010be27906a2&c_uniq_tag=H4rj3T0pyvmFk1Sl8Q8zwRBugzyfhCeq9bQIj38dgmQ&type=album', 'https://sun9-53.userapi.com/impg/3pmPhPiyOMDJ-gm5AU0_-yqtmGCTMhMvMvzexw/Gpox8UHKOoI.jpg?size=97x130&quality=95&sign=f9712a9cfce865c562a0b2cc00846299&c_uniq_tag=Iau02HdMezjE8QGy1G3B62xUglayMEs2R9-llQUeSJY&type=album', 'https://sun6-22.userapi.com/impg/bKm4yFBEImCqJn0ClViOxBxwWTIC5A18sNsN4Q/iL16EFUJQn4.jpg?size=97x130&quality=95&sign=47e07104f8282487b9e0785b936a5831&c_uniq_tag=P9HoZiQgBMOLLbOAN8NsD-YRx6b1DBjGTuawKwHeChc&type=album', 'https://sun9-12.userapi.com/impg/Uwj-0ZKVDy3tgOhw7JFFaMmz55j9OEpRgd3jkg/RB4KPk7ouT0.jpg?size=130x97&quality=95&sign=3daf7db8c0b2a3b5829f8d2d34f9c78d&c_uniq_tag=fgPIrorbTxgwEgijkFuF77IZ-0M_baFYD_rPo_jAJEY&type=album', 'https://sun9-66.userapi.com/impg/21qL8yIr6QyjLjbeEWpV8TpV71wdxBzqeIVQPA/k_662mZ7XMk.jpg?size=97x130&quality=95&sign=c22d32b529ba44736cc10777eebce2e9&c_uniq_tag=3HwST_6bcFVvDHfW3XI1zMs67aH68SrHiSb2RLA5u6I&type=album', 'https://sun9-19.userapi.com/impg/dq-HudH9MIap30CS_1bPtS8YfZGJ82rBOcqXBw/NVkbGq4P5ek.jpg?size=130x97&quality=95&sign=899b2c4268e101d6e0d55e16bb9cd81e&c_uniq_tag=2Sz46fkhUZlL0MbWjEBUTtQMCBxKJmR79SadGeJqbEw&type=album']
        },
        texts: {
          header: {
            white: 'Старые года и большие цифры пробега',
            bronze: '— это плохо?',
          },
          description: 'К нам обратился клиент с просьбой подобрать Hyundai Santa Fe. Год и пробег для него был не важен, акцент был только на самом состоянии. Почему это правильная позиция? Расскажем в этом посте!',
          ending: 'Иногда может быть такое, что стоит отбросить требования по пробегу и году выпуска в удел качеству и состоянию автомобиля. В данном вопросе наши специалисты вас с радостью проконсультируют.',
        },
        vehicle: {
          name: 'Hyundai Santa Fe',
          production_year: '2013',
          engine: '2.4 АТ (175л.с.).',
          mileage: 140000,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_1209',
      },
      {
        images: {
          big: ['https://sun9-12.userapi.com/impg/SPxT060jAC3-EIfGPaqfitOKmX-aQuOQmGdKXg/yRrUuiAzMRU.jpg?size=453x604&quality=95&sign=4ab354256fb1f83728288c7335d44b34&c_uniq_tag=A2FoOBL2gzsf70gHilBrYUYYfm3RVQHUxUbK4W4ZZ1A&type=album', 'https://sun9-17.userapi.com/impg/hJK7AXkKOeyqEXvh6Nh3cBu5d6n7MlEMYAUHHA/FO8FANTc7CQ.jpg?size=453x604&quality=95&sign=1838aa4fb29d29e91d34335dfe0f77ed&c_uniq_tag=kEafDxyhS--z6PjmkApzBzhkHI4vrOp5rEhsRK5otq8&type=album'],
          small: ['https://sun9-36.userapi.com/impg/uyA2eo6IjIuCo0gq6eFRCzUeypeqskdXfQbJew/vGAvFkY2_5k.jpg?size=453x604&quality=95&sign=768fcbcd87172f82b639e8e917b618a2&c_uniq_tag=ka_I2-stzS5gkWAG4fQPSZfN7izkM5urW3qKUe-JHvg&type=album','https://sun9-35.userapi.com/impg/8nHuPTZ0xG21gP_eEo_mU5DUn-F-6aQWeNWEhQ/l34NN4B3Cb4.jpg?size=453x604&quality=95&sign=05f7e4147cd8857fe17fa799934f3455&c_uniq_tag=mJkzmUx50LH04EGK0_IbuQAbceLfN1kha7x1DbHdhao&type=album','https://sun9-21.userapi.com/impg/7HN8-6qtTfm3MaXR2DnjgryaBKv40SemJgNqrg/YOusE3B65LY.jpg?size=453x604&quality=95&sign=7db9f862dc085d4471da591a3f0a42dc&c_uniq_tag=2_qYYCqaxmUon4rM-3seBFLimJjO4iSKxxv9Bq3djio&type=album','https://sun9-23.userapi.com/impg/TadvE45EDhqKWoj7QtE5_5bWjJzCu1IGD7nMCQ/yyvHSn4jMJs.jpg?size=453x604&quality=95&sign=a8440de5c69f9c61a0d06bbd0d73e947&c_uniq_tag=0Dj0R7LT9_1XhP71zdZp-JmngdyANMUBxl6qogpkApo&type=album']
        },
        texts: {
          header: {
            white: 'Подобрали автомобиль с интереснейшей комплектацией',
            bronze: 'для клиента из ДНР!',
          },
          description: 'К нам обратился клиент из Донецка (ДНР) с просьбой найти VW Polo в новом кузове на японском автомате с 1.6 двигателем. По его запросам начали искать на автомобильных порталах. Спустя небольшое количество времени мы обнаружили хороший вариант в виде Volkswagen Polo в комплектации Football Edition. Наша команда оперативно выехала на осмотр автомобиля. Об итогах — уже сейчас!',
          ending: 'Вместе с нашим клиентом из ДНР мы всего за один день оформили машину в МРЭО и сопровождали на протяжении абсолютно всей сделки, до тех пор, пока машина полностью не подготовилась к новому владельцу.',
        },
        vehicle: {
          name: 'Volkswagen Polo',
          production_year: '2021',
          engine: '1.6 AT (110л.с.).',
          mileage: 58000,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_1149',
      },
      {
        images: {
          big: ['https://sun9-5.userapi.com/impg/EMNlHo8p9Zm9cfiyAp8aEH0EsAx9g87asYaeGA/yLGeNmmkT4w.jpg?size=453x604&quality=95&sign=7af2eab2d08d5090c624cd560326f93d&c_uniq_tag=MnSP2WKMuarf3u9h2AU3KOz3Xhc1FeTzoCjlPZTD0lE&type=album', 'https://sun9-16.userapi.com/impg/kN7PFjDsvk88-g9TA9eydkWJVsxAZJ38FdEnfA/J2Pge7s5-6k.jpg?size=453x604&quality=95&sign=0429a37ade06f2f3e18cf7ffa8d88edb&c_uniq_tag=oVUtHdzZwipYhgWAf_XQzibiXpW9Jr2lm1QS8vlLm7I&type=album'],
          small: ['https://sun9-57.userapi.com/impg/kMOrNNfPmkfXAxrAvyVc6i-w6ISQCLZM5MUS1g/cTFb2rv_M_M.jpg?size=453x604&quality=95&sign=20343e9d34d9849efae6fc7ef439fd2b&c_uniq_tag=8JrHEB2wVWOHI24kNF8S7uywa1Jum2TB6SialYO0b14&type=album', 'https://sun9-45.userapi.com/impg/fZUT7JDrKWDn1xeexcxMA7jYQWzWP5n4Lg6LdQ/FY45vaHTV0Y.jpg?size=604x453&quality=95&sign=fb3302df6f31b65dea63d5ecb4ca8136&c_uniq_tag=05tlpHYb2ApJaQOQL14utJVWrEjycaNWk83sQcQUUV4&type=album']
        },
        texts: {
          header: {
            white: 'Подобрали Hyundai Creta',
            bronze: 'ниже рынка на 200,000 рублей!',
          },
          description: 'Объявление выходит ровно в 21:00, после первичной проверки юридической части, мы скидывали на одобрение авто клиентам. После обсуждение всех нюансов, созваниваемся с продавцом и решаем разом скинуть задаток, чтобы забронировать автомобиль (ведь ажиотаж на дешевые авто появляется сразу). Смелая авантюра, ведь плохой автомобиль никто не отменял. Но с другой стороны, кто ничем не рискует, тот не получает автомобиль ниже рынка.',
          ending: 'На этот раз наша команда отправилась на проверку автомобиля в Черткова (300 км от Ростова).',
        },
        vehicle: {
          name: 'Hyundai Creta',
          production_year: '2017',
          engine: '1.6 AT (123 л.с.)',
          mileage: 82000,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_966',
      },
      {
        images: {
          big: ['https://sun9-47.userapi.com/impg/oOi_Trgbl7a1RJM-rT46YT1qHcXKhCGU07rgQg/oOSSm9R1gK8.jpg?size=453x604&quality=95&sign=715e58c4d9423f3d169ec644389de733&c_uniq_tag=vi7DGguvrL9v_HWLnpc74KGoZ88xnuovaYqVYikT6Gs&type=album', 'https://sun9-76.userapi.com/impg/h_D4JYdIszqgZEaWs7VP9huJg_zMM32OSra3ZQ/V2uR8reUKXc.jpg?size=810x1080&quality=95&sign=1211704979f0b02041ffb6ee4c3fc510&type=album'],
          small: ['https://sun9-50.userapi.com/impg/_2lmDs_K3Vr4nKgqGWtHiGxuKqZnJKZUmRSHqw/GyY0fuba7vI.jpg?size=453x604&quality=95&sign=e2e2ff924c5e63f32a26eb0c3949794e&c_uniq_tag=LFEbEUod_yx_BJwHFd457VvWxJGLh-PBdC7pmjdNW8c&type=album', 'https://sun9-17.userapi.com/impg/R4y0shHxZ5dUUgkjhIDHM6koYlEjlX3ERUwQSg/BBkN3Ac0Ooo.jpg?size=453x604&quality=95&sign=986831f89223ef311a14ef618efbb3e1&c_uniq_tag=HL3AzWMEyz42Knfe1qOF14RUdVGOzp0CJauDFRjMN2Y&type=album']
        },
        texts: {
          header: {
            white: 'Kia Rio',
            bronze: 'за которую шла борьба',
          },
          description: 'За последние пару месяцев в Ростове стал острый вопрос с количеством хороших автомобилей на рынке. И как правило надежный автомобиль уходит за 30 минут после его публикации. Так случилось и с нашим клиентом, для которого мы подбирали автомобиль.',
          ending: 'Автомобиль был полностью рекомендован к покупке, желаем Владимиру и его семье успехов на дороге!',
        },
        vehicle: {
          name: 'Kia Rio',
          production_year: '2014',
          engine: '1.6 MT (123 л.с.)',
          mileage: 75000,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_929',
      },
      {
        images: {
          big: ['https://sun9-75.userapi.com/impg/pGQg0-7mEUv_nU1ojRleM4mD0-m_uqNIGyr35g/uOWxoLhA7qA.jpg?size=453x604&quality=95&sign=b6aea3adfb2d0bdf2b289ea51bca004f&c_uniq_tag=_broRjnZoVUkhVprFTIF3ATirlo141TlJRM2Y4n1RfU&type=album', 'https://sun9-72.userapi.com/impg/CtsHxNWGgQn0O-fi9PjL4EJo9VkkddGbKWK2pw/EqL4Cv3RRU4.jpg?size=453x604&quality=95&sign=ede851f6a3fdcd2f11760ce093b52fef&c_uniq_tag=Cud43kB9_3SfUhfnijtlWUTzxpbqz2hhDuZh7l0UPBU&type=album'],
          small: ['https://sun9-57.userapi.com/impg/nuiNKG3sOaEeaU2Pi3VSJYn9pczTnP_ezD149w/JnFEg8r9OJI.jpg?size=453x604&quality=95&sign=55beec923a48ee3239eac75e4eadfa98&c_uniq_tag=n3DhJY59AbI2AWcYwTyQy-MtlqHnCayHNSDg2TXA4Eg&type=album', 'https://sun9-54.userapi.com/impg/VfnMzNISGAfgD7fspNquW1NYAlsoABymLu5t_A/2h7Dg8IoYOU.jpg?size=604x453&quality=95&sign=7f6fb258f0a88615cc55b3457cfccb70&c_uniq_tag=6DS1XK7SHLIt2_CRNB6xn_ChVXumzGt_XmIp867QQpI&type=album']
        },
        texts: {
          header: {
            white: 'Подбор сроком',
            bronze: 'в 1 день',
          },
          description: 'Недавно к нам обратился Егор с просьбой найти Mazda 6 в хорошем состоянии. Главным критерием было то, чтобы авто было для РФ, без аукционных авто из США.',
          ending: 'Помимо подбора авто, помогли клиенту оформить сделку, переоформить права и вывезти за город. Желаем Егору и его семье только положительных эмоций!',
        },
        vehicle: {
          name: 'Mazda 6',
          production_year: '2014',
          engine: '2.5 AT (192 л.с.)',
          mileage: 108000,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_897',
      },
      {
        images: {
          big: ['https://sun9-65.userapi.com/impg/lz1JQl8g1mG3QPhgy1vde7yPZgeg7Ea5587CzQ/Mh9pUNsg9JE.jpg?size=453x604&quality=95&sign=e2ca93951a5e819726225b14395513be&c_uniq_tag=5VmrwkJnEjzkYJfykNdGqDA0cK-YDquSqm_FAn9wOfI&type=album', 'https://sun9-61.userapi.com/impg/TUGo7AA7wLZXMeWhzBDxRpEDjuj2fMy-1P8TLA/DIdWtRHURow.jpg?size=453x604&quality=95&sign=8773d3d233ce53e1020ea6300134fa25&c_uniq_tag=3BkE0ixuipdC6MOvTWxLnIVsM5A-wUUIvxKWVxBMho8&type=album'],
          small: ['https://sun9-50.userapi.com/impg/MGSjzTS0rZygpmYI3Qz4riFAeHKk2pvOFn7w-Q/cQvsCmuWGZs.jpg?size=604x453&quality=95&sign=8fd98ca300d43bc9e91bd48e44aa6450&c_uniq_tag=Th-Vbe4NKZVwAuAelh5t8WKfpWG53_k7PAjq1wCVvV0&type=album', 'https://sun9-71.userapi.com/impg/RsSkege8eSZo1vRfD29NfBQMVPy0ftkTo5JHvw/ixHHIKEtWnc.jpg?size=604x453&quality=95&sign=1b6cb0ce09a02447d52e55a635cb6445&c_uniq_tag=Af5vaYBbjkGkvGGj27lTJZgSDujMxz4JOJkhjBNVSIs&type=album', 'https://sun9-63.userapi.com/impg/AnNWFwr4b0iPX2vbPgf2MMXxNoqC0mGQOFdDoA/ePOgoi2XAvo.jpg?size=453x604&quality=95&sign=c0d504b1e3fc58592a7efa7f26ac2096&c_uniq_tag=qESCVGxDYJM6aU3a4XP4hCuTbsDM8sNG_lxravInn3o&type=album']
        },
        texts: {
          header: {
            white: 'Легко ли найти дорогой автомобиль',
            bronze: 'в России?',
          },
          description: 'У многих складывается миф, если машина стоит существенную сумму — значит её легко найти. К сожалению, именно на такой расчёт надеются многие перекупы и продавцы. Сегодня мы расскажем о подборе автомобиля для нашего клиента. Заказ стаял четкий: RAV4, полный привод, двушка двигатель и никаких пригонышей с мутной историей.',
          ending: 'Помогли оформить автомобиль одним днём, прикупить резину с литыми дисками, вывезли за город и полностью сопроводили весь цикл покупки автомобиля от А до Я.',
        },
        vehicle: {
          name: 'Toyota RAV4',
          production_year: '2020',
          engine: '2,0 AT (149 л.с.)',
          mileage: 49735,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_859',
      },
      {
        images: {
          big: ['https://sun9-79.userapi.com/impg/KPLkWoJwVRqvwF9l5QygzMaSrPzmY85tmZpWDw/6a3bE7VrGIQ.jpg?size=453x604&quality=95&sign=b06f6e1e480c978ee7c15a76f9416b51&c_uniq_tag=6IUPL5wIXQHvG97NIfS_-YVWNE94lcgcZv0doAnEBgo&type=album', 'https://sun9-49.userapi.com/impg/VoyVPyLFWxq12y1SrB01iAwMRSmceb5NigeYdw/kVdlzF4PGOc.jpg?size=453x604&quality=95&sign=8219d493660acd176865eb9d75e0175e&c_uniq_tag=6P4xdz2TqBG8ntp4cQQphFri4Qd0qs3OGi0xALx-0Ho&type=album'],
          small: ['https://sun9-58.userapi.com/impg/orSwHndxDTF23VJZHbfYzmL_q27kkKDd0VAq8A/1nNBk2ref0A.jpg?size=453x604&quality=95&sign=03a15ebb09530d896d157c02333a26d0&c_uniq_tag=oiI0_GgzwBjrnfXep36bZfLdiPciOtftWptitNYok_k&type=album', 'https://sun9-15.userapi.com/impg/JNXCrwoya0K7jm0CN_lDGQh7SweHjNszPyz4_g/Yb8enu-mV3w.jpg?size=453x604&quality=95&sign=1273d93f4ad82eb5afcfdfdf593a29c0&c_uniq_tag=NsLJd9VjTp2wapGMUQsMrpy3zCYJ5nIIs2BFJVUEpn8&type=album', 'https://sun9-65.userapi.com/impg/SI0XohXJET6epu8q41qcq6H4w12levnl3Ycl6A/evnk63Buy5s.jpg?size=453x604&quality=95&sign=9c279508bc380b495dc45e10f874e0c0&c_uniq_tag=BVfpQTb9FhYcLl6bxadNn0c3m7v_HnACCxeurdIJkHs&type=album']
        },
        texts: {
          header: {
            white: 'Бюджетный кроссовер',
            bronze: 'с 2-х литровым двигателем!',
          },
          description: 'Цены на автомобили выросли почти в 2 раза, но какой кроссовер остался одним из самых надежных и бюджетных в рынке? К нам обратилась семья из Донецка (ДНР) с заказом на полноприводный кроссовер с механической коробкой передач, но при этом были ограничены в бюджете. Остановились на Renault Duster.',
          ending: 'После подбора автомобиля, мы полностью провели всю сделку по покупке, подобрали ребятам хорошую зимнюю резину, оформили за пару чаЦсов все документы в МРЭО и отправили в добрый путь на границу!',
        },
        vehicle: {
          name: 'Renault Duster',
          production_year: '2017',
          engine: '2.0 4x4 MT (143 л.с.)',
          mileage: 61470,
        },
        link: 'https://vk.com/avtolabs?w=wall-214514268_399',
      },
    ],

    opinions: [
      'https://media.discordapp.net/attachments/785161343681560608/1088167574744870922/image.png',
      'https://sun9-47.userapi.com/impg/cznCV-h6ofKa54I8vXchdFpFVunVJL_dUAzvLg/o_GgJOE02h4.jpg?size=545x524&quality=96&sign=d1cd65db0bd54a6a1c7e510497379196&type=album',
      'https://sun9-3.userapi.com/impg/6Xtpe-XVPvxKp3HAkFZCqccQ7vg4ribXxwDCFg/7xrgez2a2F0.jpg?size=545x400&quality=96&sign=0a9fd9da8a31a1f52ba8c7258205a57a&type=album',
      'https://sun9-39.userapi.com/impg/NKtU3NcayKRlPmmSK0N2x7Y4UjE6kqI2J8S4fA/1JPgHN19nQU.jpg?size=546x220&quality=96&sign=0875cdffeea86bd45008c4eb77946a1c&type=album',
      'https://sun9-57.userapi.com/impg/tVR-Z5xC1ltBdl6V8ZqSfCeULlr_PifaduhatQ/a_N9sk0FH2c.jpg?size=547x205&quality=96&sign=d152ec94d7790cda839c392f25901aa8&type=album',
      'https://cdn.discordapp.com/attachments/785161343681560608/1090970314055352320/iZRxMgHqsck.png',
      'https://cdn.discordapp.com/attachments/785161343681560608/1088171487619465266/image.png',
      'https://sun9-28.userapi.com/impg/XBfGgFZpGALWUs2bG4SMTXdy9166DCoU8qf8dA/KDOI5G4wVdU.jpg?size=539x202&quality=96&sign=3b977577e4570ca6f6c5d0686b17b24f&type=album',
      'https://sun9-78.userapi.com/impg/DtHftG2iy56LgRKjocxV4gSuKqPt0kv6uRS_8g/BEMP7_VRfB4.jpg?size=545x181&quality=96&sign=d7cf352031d07b70e2f3b9848e017657&type=album',
      'https://sun9-54.userapi.com/impg/fiJmbiEOuBv_UrBn4-Cb9-NfQV-SuHRKI4Cniw/5-GcaQ-IpBw.jpg?size=544x168&quality=96&sign=ed76b5de7e4442aae227f674c2165ade&type=album',
      'https://sun9-80.userapi.com/impg/GgvvhEJ50ChHtl_ZrblAE9SSJnvWH26055mMbQ/ca9iMkJetuA.jpg?size=546x164&quality=96&sign=0cdf3184637920dca60b8f91a799bac6&type=album',
      'https://sun9-9.userapi.com/impg/5geVsskV7s66TGuhJo4r8rTTFossxpufgl6-Xw/81s4WFvs9Rk.jpg?size=546x161&quality=96&sign=f4e28f74b255b6fe921eacc08c92597d&type=album',
      'https://cdn.discordapp.com/attachments/785161343681560608/1088171459966402591/image.png',
      'https://sun9-80.userapi.com/impg/gz91N3vhcacWknwCS106RTol3ZxQeKv0S2GvFw/UHOU_y51oq0.jpg?size=539x158&quality=96&sign=d384ed9172d8de8d66eb2dd71961e461&type=album',
      'https://cdn.discordapp.com/attachments/785161343681560608/1090332896864243842/image.png',
      'https://sun9-27.userapi.com/impg/mvtLPjusUc-2tEupxjcTLv7OF7Rh94ML8NPhWg/4R5Zf8uChvs.jpg?size=546x154&quality=96&sign=d51e6f726284cca520ced2521867b7de&type=album',
      'https://sun9-63.userapi.com/impg/tVIsr_3UycjYKWJo1Nwwd0ebz70-cxOWJ75MGQ/qIkbW9KoTJI.jpg?size=540x122&quality=96&sign=148b70f08e3193573ad8dec45b733043&type=album',
      'https://sun9-14.userapi.com/impg/aMV8P7DA5HT3WMJwE4VZshm--OqoNQDjK7k0fg/s45wcjAxCRM.jpg?size=543x115&quality=96&sign=9c4a5b0f99b7da96b0e709314f1692e7&type=album',
      'https://sun9-21.userapi.com/impg/m76hzmg0JtzXbyg_9grxFVBvbvkQaRCypCjByQ/FQb1fJkGH2I.jpg?size=548x93&quality=96&sign=e887e9a237c13100a0b140182f5f2ef9&type=album',
      'https://sun9-63.userapi.com/impg/bX50Mll7eP1itFWLB9_wPLCBc5YMefgi9lxp-A/Z_sjBe15M7w.jpg?size=548x88&quality=96&sign=4a3f0b4997ae84ee6ec69f630a93fe07&type=album',
      'https://sun9-62.userapi.com/impg/gNGJ85IPNNrV7R1MymAJVmAO-ps5pE7SmpkV5A/tyyXLgh4zSc.jpg?size=545x87&quality=96&sign=d3ac0b9dbe3af49c0b5d6e9db804346b&type=album',

    ]
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
